.letterboard-output-container {
  padding-top: 25px;
  width: 50%;
}

.letterboard-output-section {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient( #010101 65%, #212121 65%) top center / 100% 10px repeat-y;
  border: 10px brown solid;
  box-sizing: border-box;
}